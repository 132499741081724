<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Edit Timezone</h2>
    </template>

    <template #page-content>
      <div id="edit-timezone">
        <div v-if="currentUser != null">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(save)">
              <div
                class="buttons"
                style="border-bottom: 0.1px solid rgba(100, 100, 100, 0.1)"
              >
                <b-button
                  type="is-primary"
                  outlined
                  icon-left="close"
                  :disabled="isSaving"
                  @click="$router.push('/settings/personal-info')"
                >
                  Cancel
                </b-button>
                <b-button
                  type="is-primary"
                  icon-left="check"
                  native-type="submit"
                  :loading="isSaving"
                >
                  Save
                </b-button>
              </div>

              <p class="title">Time Zone Information</p>

              <b-field label="Time Zone">
                <b-select
                  rules="required"
                  placeholder="Select a Time zone"
                  v-model="selectedTimezone"
                >
                  <option
                    v-for="option in listTimezone"
                    :value="option"
                    :key="option"
                  >
                    {{ option + '' + getTimezonesOffset(option) }}
                  </option>
                </b-select>
              </b-field>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </template>
  </r-page>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment-timezone'
import ApiService from '../../../services/common/api.service'
import { showToast } from '../../../services/util'

export default {
  watch: {
    currentUser: function () {
      if (this.currentUser != null) {
        this.selectedTimezone = this.currentUser.timezone
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'user/getUserInfo',
    }),
  },
  data() {
    return {
      isSaving: false,
      listTimezone: moment.tz.names(),
      selectedTimezone: null,
    }
  },
  methods: {
    ...mapMutations({
      setTimezone: 'user/setTimezone',
    }),
    async save() {
      try {
        await ApiService.post('/api/space-roketin/user/update-timezone', {
          timezone: this.selectedTimezone,
        })

        this.setTimezone(this.selectedTimezone)
        showToast('Timezone updated', 'is-success', 'is-bottom')
      } catch (err) {
        console.log(err)
      }
    },
  },
  mounted() {
    if (this.currentUser != null) {
      this.selectedTimezone = this.currentUser.timezone
    }
  },
}
</script>
